.all-source_container {
    grid-column: 2 / 14;
    row-gap: 10px;
    column-gap: 10px;
    margin: 8px;
  }

.all-sources {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.all-sources h1 {
    margin-bottom: 20px;
}

.all-sources table {
    width: 100%;
    border-collapse: collapse;
}

.all-sources th, .all-sources td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.all-sources th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.all-sources tr:nth-child(even) {
    background-color: #f9f9f9;
}

.all-sources tr:hover {
    background-color: #f5f5f5;
}



.button-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #feef00; /* 绿色背景 */
    color: rgb(95, 95, 95);
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
    border-width: 0px
}

.button-link:hover {
    background-color: #fff89c; /* 鼠标悬停时的深绿色 */
}

.add_source {
    margin-bottom: 20px;
}