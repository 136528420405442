.top_news_container {
  grid-column: 5 / 14;
  row-gap: 10px;
  column-gap: 10px;
  margin: 8px;

  background-color: white;
  height: auto;

  margin: 8px;
  padding: 16px;
  border: #f0f0f0;
  border-width: 2;
  border-radius: 8px;

  text-decoration: none;

  display: flex;
  flex-direction: column; /* Ensure the flex direction is column */
  flex: 200px; /* Set a fixed width for demonstration purposes */
  align-items: flex-start;
}


.news_date {
  margin: 2px;
  margin-right: 8px;
  color: rgb(27, 27, 27);
  font-size: small;
}


.news_content {
  margin: 2px;
  color: black;
  text-align: left;
  overflow: visible;
  max-width: 100%; /* Ensures the content stays within the parent width */
  word-wrap: break-word; /* Allows words to break onto the next line */
  overflow-wrap: break-word; /* Handles long words to prevent overflow */
}

.news_content code {
  display: block; /* Ensure it takes up the full width */
  white-space: pre-wrap; /* Allows the text to wrap */
  word-wrap: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Prevents overflow for long text */
  max-width: 100%; /* Ensures it stays within the container */
  background-color: #f5f5f5; /* Optional: a background to make the code stand out */
  padding: 8px; /* Optional: padding for better readability */
  overflow-x: auto; /* Allows horizontal scrolling if the content is too wide */
  border-radius: 8px; /* Optional: rounded corners for styling */
}