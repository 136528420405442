div.nav_container {
    border-style: dashed;
    border-radius: 8px;
    height: fit-content;
    margin-top: 16px;
    background-color: white;
    padding: 16px;
    align-items: left;
}

.nav_title {
    margin-bottom: 0px;
    font-weight: bold;
}

div.nav_container > ul > li {
    text-align: left;
}

div.nav_container > ul > li > a {
    text-decoration: none;
}