.App {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background-color: #f0f0f0;;
  min-height: 100vh;

  display: grid;
  grid-template-columns: repeat(14, 1fr);
  row-gap: 0px;
}

.header_spacer {
  height: 130px;
  grid-column: 1 / 15;
}

.sidebar {
  grid-column: 2 / 5;
}

.notice {
  border-style: dashed;
  border-radius: 8px;
  height: fit-content;
  margin-top: 16px;
  background-color: white;
  padding: 16px;

}

.news_card_conatiner {
  grid-column: 5 / 14;
  row-gap: 10px;
  column-gap: 10px;
  margin: 8px;
}

.news_card {
  background-color: white;
  height: auto;

  margin: 8px;
  padding: 16px;
  border: #f0f0f0;
  border-width: 2;
  border-radius: 8px;

  text-decoration: none;

  display: flex;
  flex-direction: column; /* Ensure the flex direction is column */
  flex: 200px; /* Set a fixed width for demonstration purposes */
  align-items: flex-start;
}

.news_card_headline {
  margin: 2px;
  color: black;
  font-size: larger;
  text-align: left;
}

.news_card_meta {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.news_card_date {
  margin: 2px;
  margin-right: 8px;
  color: rgb(27, 27, 27);
  font-size: small;
}

.news_card_source {
  margin: 2px;
  color: rgb(84, 84, 84);
  font-size: small;
}

.news_card_content {
  display: -webkit-box; /* WebKit browsers */
  display: flex; /* Non-WebKit browsers */
  -webkit-line-clamp: 3; /* WebKit browsers */
  line-clamp: 3; /* Non-WebKit browsers */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical; /* WebKit browsers */
  margin: 2px;
  max-height: 4em; /* Set a maximum height to ensure the line-clamp works */
  text-align: left;
  color: rgb(157, 157, 157);
}


.news_card_tag_list {
  display: flex;
  margin: 2px;
  margin-top: 8px;
  gap: 8px; /* Gap between tags */
}

span.news_card_tag {
  color: #feef00;
  font-size: small;
  font-weight: bold;
  background-color: rgb(254, 239, 0, 0.1);
  border-style: solid;
  border-color: #feef00;
  border-radius: 10px;
  padding: 2px 4px 2px 4px;
}

h3.collection_title {
  text-align: left;
  margin-left: 26px;
}