li.source {
  text-align: left;
}

.side_bar > a {
  text-align: center;
  font-weight: bold;
}

.added_source {
  margin-bottom: 0px;
  font-weight: bold;
}

.add_source {
  margin-top: 0px;
}

.add_source > a {
  text-decoration: none;
  font-size: small;
}

h5.source_type {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}

ul.source_ul {
  margin-top: 0px;
}

.source.selected > span {
  background-color: #feef00;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 5px;
}

.button-link_s {
  display: inline-block;
  padding: 2px 4px;
  background-color: #feef00; /* 绿色背景 */
  color: rgb(95, 95, 95);
  text-decoration: none;
  border-radius: 2px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

.button-link_:hover {
  background-color: #fff89c; /* 鼠标悬停时的深绿色 */
}