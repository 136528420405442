@font-face 
{
    font-family: 'DepartureMono';
    font-style:normal;
    font-weight:400;
    src:
      url(../fonts/DepartureMono-Regular.woff2) format("woff2"),
      url(../fonts/DepartureMono-Regular.woff) format("woff"),;
}


.header {
  background-color: #000;
  padding: 30px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

  position: fixed;
  width: 100%;
  height: 70px;

  display: grid;
}

.headline_container {
  grid-column: 2 / 14;
  text-align: left;
  color: white;
  font-family: DepartureMono;
}

.headline {
  margin: 0;
  font-size: xx-large;
  font-style: italic;
  color: #feef00;
}

.headline-glitch
{
    animation: cyber-glitch-3 1s linear infinite;
}

.motto {
  margin: 0;
  font-size: medium;
}


@keyframes cyber-glitch-3 
{
    0% {visibility: visible;}
    49% {visibility: visible;}
    50% {visibility: hidden;}
    100% {visibility: hidden;}
}