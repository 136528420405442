.add_container {
    grid-column: 2 / 14;
    row-gap: 10px;
    column-gap: 10px;
    margin: 8px;
  }

.add {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.add h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.add form {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.add form > div {
    margin-bottom: 15px;
}

.add label {
    display: block;
    margin-bottom: 5px;
    color: #555;
}

.add input[type="text"],
.add input[type="email"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.add input[type="radio"] {
    margin-right: 5px;
}

.add button[type="submit"] {
    display: inline-block;
    padding: 10px 20px;
    background-color: #feef00;
    color: rgb(95, 95, 95);
    text-decoration: none;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-size: 16px;
}

.add button[type="submit"]:hover {
    background-color: #e6d800;
}

.add .radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.add .radio-group label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.add .message {
    margin-top: 15px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

.add .message.success {
    background-color: #d4edda;
    color: #155724;
}

.add .message.error {
    background-color: #f8d7da;
    color: #721c24;
}


.status-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.status-on {
    background-color: #4CAF50;  /* 绿色 */
}

.status-off {
    background-color: #F44336;  /* 红色 */
}